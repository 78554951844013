<template>
	<div>
		<div class="wrap">
			<div class="pushTask" v-for="(item,index) in taskKeywordsVo" :key="index">
				<div class="add_btn" v-show="index == 0">
					<el-button size="medium" plain @click="addShopModel(index)"><i class="el-icon-plus"></i>添加</el-button>
				</div>
				<div class="add_btn" v-show="index != 0">
					<el-button size="medium" plain @click="delShopModel(index)"><i class="el-icon-minus"></i>删除</el-button>
				</div>
				<el-form :model="taskKeywordsVo[index]" :rules="rules" :ref="'searchForm'+index" >
					
					<el-row style="margin-bottom: 25px;" >
						<el-col :span="24">
							<el-form-item label="任务方式：" prop="taskEntranceId">
								<el-radio-group v-model="taskKeywordsVo[index].taskEntranceId" @change="taskTypeChange($event,index)">
									<el-radio :label="typeItem.entranceId" style="margin-bottom: 10px;"
										v-for="(typeItem,typeIndex) in typeList" :key="typeIndex">
										<span>{{typeItem.entranceName}}</span>
									</el-radio>
								</el-radio-group>
							</el-form-item>
						</el-col>
					</el-row>
					<!-- 搜索关键词-->
					<el-row style="margin-bottom: 25px;" v-if="taskKeywordsVo[index].entranceFlag == 1">
						<el-col :span="11">
							<div class='flexAlignCen'>
								<el-form-item 
								label="搜索关键词：" 
								prop="keywords" 
								label-width="120px"
								:rules="[{required:true,message:'请输入搜索关键词',trigger:['change']}]">
									<el-input v-model="taskKeywordsVo[index].keywords" style="width: 150px;"></el-input>
								</el-form-item>
								<!-- <span class="checkRank" @click="checkRank">查询排名</span> -->
							</div>
						</el-col>
						<el-col :span="7">
							<div class='flexAlignCen'>
								<el-form-item label="发布单数：" prop="totalQuantity"  label-width="100px">
									<el-input
                      v-model="taskKeywordsVo[index].totalQuantity"
                      style="width: 60px;"
                      @change="inputChange"
                      oninput="value=value.replace(/[^\d]/g,'')">
                  </el-input>
								</el-form-item>
								<span style="margin-left: 8px;">单</span>
							</div>
						</el-col>
					</el-row>
          <!-- 二维码-->
					<el-row style="margin-bottom: 25px;" v-if="taskKeywordsVo[index].entranceFlag == 2">
						<el-col :span="11">
							<div class='flexAlignCen'>
								<el-form-item 
								label="二维码：" 
								prop="keywords"
								label-width="120px"
								:rules="[{required:true,message:'请上传二维码',trigger:['change']}]">
									<sb-upload :imageUrl="taskKeywordsVo[index].keywords" type="19" :imgStyle="{width:'68px',height:'68px',lineHeight:'68px'}"  @handleSuccess="handleSuccess($event,index)" ></sb-upload>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="7">
							<div class='flexAlignCen'>
								<el-form-item
                    label="发布单数："
                    prop="totalQuantity"
                    label-width="100px"

                    oninput="value=value.replace(/[^\d]/g,'')">
									<el-input v-model="taskKeywordsVo[index].totalQuantity" style="width: 60px;"  @change="inputChange"></el-input>
								</el-form-item>
								<span style="margin-left: 8px;">单</span>
							</div>
						</el-col>
					</el-row>
          <!-- 口令 -->
					<el-row style="margin-bottom: 25px;" v-if="taskKeywordsVo[index].entranceFlag == 3">
						<el-col :span="11">
							<div class='flexAlignCen' >
								<el-form-item 
								label="口令：" 
								prop="keywords"
								label-width="120px"
								:rules="[{required:true,message:'请输入口令',trigger:['change']}]">
									<el-input v-model="taskKeywordsVo[index].keywords" style="width: 150px;"  @change="inputChange"></el-input>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="7">
							<div class='flexAlignCen'>
								<el-form-item
                    label="发布单数："
                    prop="totalQuantity"
                    label-width="100px"
                    @change="inputChange"
                    oninput="value=value.replace(/[^\d]/g,'')">
									<el-input v-model="taskKeywordsVo[index].totalQuantity" style="width: 60px;"  @change="inputChange"></el-input>
								</el-form-item>
								<span style="margin-left: 8px;">单</span>
							</div>
						</el-col>
					</el-row>
					<!-- 拍立淘 -->
					<el-row style="margin-bottom: 25px;" v-if="taskKeywordsVo[index].entranceFlag == 4">
						<el-col :span="11">
							<div class='flexAlignCen'>
								<el-form-item label="请上传商品相识图片：" prop="keywords"  label-width="120px" >
									<sb-upload :imageUrl="taskKeywordsVo[index].keywords" type="19" :imgStyle="{width:'68px',height:'68px'}" @handleSuccess="handleSuccess($event,index)" ></sb-upload>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="7">
							<div class='flexAlignCen'>
								<el-form-item
                    label="发布单数："
                    prop="totalQuantity"
                    label-width="100px"
                    @change="inputChange"
                    oninput="value=value.replace(/[^\d]/g,'')">
									<el-input v-model="taskKeywordsVo[index].totalQuantity" style="width: 60px;"  @change="inputChange"></el-input>
								</el-form-item>
								<span style="margin-left: 8px;">单</span>
							</div>
						</el-col>
					</el-row>
					<!-- 直播间 -->
					<el-row style="margin-bottom: 25px;" v-if="taskKeywordsVo[index].entranceFlag == 5">
						<el-col :span="11">
							<div class='flexAlignCen'>
								<el-form-item label="二维码：" prop="keywords"  label-width="120px">
									<sb-upload :imageUrl="taskKeywordsVo[index].keywords" type="19" :imgStyle="{width:'68px',height:'68px'}"  @handleSuccess="handleSuccess($event,index)"  ></sb-upload>
								</el-form-item>
							</div>
						</el-col>
						<el-col :span="7">
							<div class='flexAlignCen'>
								<el-form-item
                    label="发布单数："
                    prop="totalQuantity"
                    label-width="100px"
                    @change="inputChange"
                    oninput="value=value.replace(/[^\d]/g,'')">
									<el-input v-model="taskKeywordsVo[index].totalQuantity" style="width: 60px;"  @change="inputChange"></el-input>
								</el-form-item>
								<span style="margin-left: 8px;">单</span>
							</div>
						</el-col>
					</el-row>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import SbUpload from '../../../components/upload/upload.vue';
	import {isInt} from '../../../tools/validate.js'
	const map = {
		entranceFlag:'',
		taskEntranceId:'',
		keywords:'',
		totalQuantity:'',
	}
	export default {
		data() {
			return {
				rules: {
					totalQuantity:[
						{required:true,validator:isInt,trigger:['change']}
					],
					taskEntranceId:[
						{required:true,message:'请选择任务方式',trigger:['blur']}
					],

				},
				map,
				checked: false,
				checkList: [],
				taskKeywordsVo:[
					// {...map}
				],
			}
		},
		props: {
			searchList:{
				default:()=>{
					return []
				}
			},
			typeList:{
				default:()=>{
					return []
				}
			},
			
		},
		watch:{
      searchList:{
        handler(newV){
          this.taskKeywordsVo = newV;
        },
        deep:true,
      },
			typeList:{
				handler(){
					if(this.searchList.length==0){
						this.addShopModel()
					}
				}
			}
		},
		methods: {
      handleSuccess(resp,index){
        console.log(resp.data)
        if (resp.code == 200){
          this.taskKeywordsVo[index].keywords = resp.data.path;
        }
      },
      validate(){
        let iss = true;
        this.taskKeywordsVo.forEach((item,index)=>{
          this.$refs['searchForm'+index][0].validate((flag)=>{
            if(!flag){
              iss = flag;
            }
          })
        })
        if(iss)this.$emit('success', {taskKeywordsVo:this.taskKeywordsVo})
        return iss;
      },
      inputChange(){
        let total = 0;
        this.taskKeywordsVo.forEach(item=>{
          if(item.totalQuantity){
            total=total+Number(item.totalQuantity);
          }
        })
        this.$emit('totalChange',total)
      },
			entranceFlag(entranceId){
				const flag = this.typeList.filter(item=>{
					return entranceId == item.entranceId;
				})
				return flag[0].entranceFlag;
			},
			taskTypeChange(id,index) {
        console.log(id)
        const flag = this.entranceFlag(id)
        console.log(id,flag)
        this.taskKeywordsVo[index].entranceFlag = flag;
        this.$set(this.taskKeywordsVo[index],'keywords','')
        this.$set(this.taskKeywordsVo[index],'totalQuantity','')
        this.$forceUpdate()
			},
			delShopModel(index){
				var data = this.taskKeywordsVo;
				data.splice(index,1);
				this.taskKeywordsVo = data
			},
			addShopModel() {
				const obj ={...map};
				obj.entranceFlag = this.typeList[0].entranceFlag;
				obj.taskEntranceId = this.typeList[0].entranceId;
				this.taskKeywordsVo.push(obj)
			},
			
			checkRank() {
				console.log('查询排序')

			}
		},
		components: {
			SbUpload,
		},
		mounted() {
			
		},

	}
</script>

<style lang="less" scoped>
	.flexAlignCen {
		display: flex;
		align-items: center;
	}

	.checkRank {
		color: @primaryColorQian;
		cursor: pointer;
		margin-left: 15px;
	}

	.pushTask {
		position: relative;
		background-color: #f5f5f5;
		width: 840px;
		border-radius: 5px;
		padding: 20px 10px;
		margin-bottom: 20px;
	}

	.wrap {

		.add_btn {
			position: absolute;
			right: -80px;
			top: 0;
			i{
				margin-right: 4px;
			}
			/deep/ .el-button--medium {
				padding: 10px 5px;
				width: 60px;
				background-color: #f5f5f5;
			}

		}
	}
</style>
