<template>
	<div>
		<el-card class="box-card">
			<div slot="header">
				进店途径与任务单数设置
			</div>
			<div>
				<el-form :model="form" :rules="formRules" ref="form" label-width="150px" class="demo-ruleForm" size="medium">	
					<!-- <el-form-item label="发布规则：" prop="platform" required>
						<el-radio-group v-model="form.type">
							<el-radio :label="1">平均发布</el-radio>
							<el-radio :label="2">急速发布</el-radio>
						</el-radio-group>
						<span class="tet" v-if="form.type == 1">平均发布是指系统会让任务在设置的时间段内平均放出任务</span>
						<span class="tet" v-if="form.type == 2">急速发布是指系统让任务在设置上线时间放出全部任务</span>
					</el-form-item> -->
					<!-- <el-form-item label="发布日期设置：" prop="platform" required>
						<el-date-picker v-model="form.date" align="right" type="date" placeholder="选择日期"
							:picker-options="pickerOptions">
						</el-date-picker>
					</el-form-item> -->
					<el-form-item label-width="0">
						<sb-search :searchList="searchList"  :typeList="typeList" @totalChange="totalNumChange" @success="searchSuccess" ref="search"></sb-search>
					</el-form-item>
					<!-- <el-form-item label="拍下订单备注留言：" prop="platform" label-width="180px">
						<el-input v-model="form.name" style="width: 150px;"  @change="transformation">
						</el-input>
						<sb-toolstips
							tip="如非必须建议不指定订单备注留言！因淘宝/京东现在人工审核申诉订单时，会排查买家的订单留言内容及发货方式是否匹配，处于安全性考虑，大家尽量通过订单号和卖家号来区分真实订单和刷单订单">
						</sb-toolstips>
					</el-form-item> -->
					<el-form-item label="设置订单成交时间："  label-width="180px" >
						<time-component :rules="rules" ref="time" @inputChange="inputChange"  :publishTotal="form.publishTotal" @success="timeSuccess" ></time-component>
					</el-form-item>
					<el-form-item label="您已设置的任务总单数：" prop="publishTotal" label-width="180px">
						<el-input v-model="form.publishTotal" style="width: 80px;" :disabled="true">
						</el-input>
						<span style="margin-left: 8px;">单</span>
					</el-form-item>

					<!-- <el-form-item label="是否到点下线任务：" required prop="platform" label-width="180px">
						<el-radio-group v-model="form.type">
							<el-radio :label="1">否</el-radio>
							<el-radio :label="2">是</el-radio>
						</el-radio-group>
					</el-form-item> -->
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import SbSearch from './search.vue'
	import { TASKENTRANCE} from '../../../api/base.js'
	// import SbToolstips from '../../../components/tooltip/tooltip.vue'
	import timeComponent from './componentForm/timeComponent.vue';
	export default {
		data() {
			return {
				formRules: {

				},
        searchList:[],
				typeList:[],
				form: {
          taskKeywordsVo:undefined,
          taskBuyHourVo:undefined,
          publishTotal: this.publishTotal || 0,
					date: Date.now(),
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < (Date.now() - 86400000);
					},
				},
			}
		},
		props: {
      cacheTwoTaskInfo:{
        type:[Object]
      },
      rules:{

      },
			cacheSearchList:{
				
			},
			platform:{
				
			}
		},
		watch:{
      cacheTwoTaskInfo:{
        handler(newVal){
          this.form.publishTotal = newVal.form?.publishTotal
        },
        deep:true,
      },
			platform:{
				handler(newVal){
					this.getGotoType(newVal)
				},
			}
		},
		methods: {
      validate(){
        const search = this.$refs.search.validate();
        const time = this.$refs.time.validate();
        if(search&&time){
          this.$emit('success',{form:this.form})
        }
        return search&&time
      },
      searchSuccess(form){
        this.form.taskKeywordsVo = form.taskKeywordsVo;
      },
      timeSuccess(form){
        console.log(form)
        this.form.taskBuyHourVo = form;

      },
      totalNumChange(total){
        this.form.publishTotal = total;
      },
			inputChange(total) {
				console.log('总数量' + total);
			},
      entranceFlag(entranceId){
        console.log(this.typeList)
        const flag = this.typeList.filter(item=>{
          return entranceId == item.entranceId;
        })
        return flag[0].entranceFlag;
      },
			getGotoType(){
				this.$axios.get(TASKENTRANCE,{platformId:this.platform}).then(resp=>{
					if(resp.code == 200){
						this.typeList = resp.data;

            this.cacheSearchList.forEach(item=>{
              item['entranceFlag'] = this.entranceFlag(item.taskEntranceId)
            })
            console.log(this.cacheSearchList)
            this.searchList = this.cacheSearchList;
					}
				})
			},
			transformation() {
			
			},
		},
		components: {
			SbSearch,
			timeComponent
			// SbToolstips,
		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {
      this.$forceUpdate()

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {
		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
		
	}
</script>

<style lang="less" scoped>

</style>
