<template>
	<div>
      <el-form :model="form" :rules="formRules" ref="form"  class="demo-ruleForm" size="medium">
        <el-form-item  prop="paymentTime" >
          <div class="flexAlignCen marBot">
            <el-date-picker
                v-model="form.paymentTime"
                type="date"
                placeholder="选择日期"

                :clearable="false"
                :picker-options="pickerOptions"
                @change="startTimeChange">
            </el-date-picker>
            <sb-toolstips tip="设置订单成交时间系统会要求买家在设置的时间段内根据所选的发布规则合理安排订单成交"></sb-toolstips>
          </div>
        </el-form-item>
        <el-form-item  prop="times"  >
          <div v-if="selectTimeInfo&&taskBuyHourVo.length !=0" class="inputWrapwrap">
            <div class="inputWrap" v-for="(item,index) in taskBuyHourVo" :key="index">
              <el-input v-model="item.totalQuantity" :disabled="item.keyHour < new Date().getHours()&&isOneDay"
                        style="width: 50px;" oninput="value=value==''?0:value.replace(/[^\d]/g,'')" @blur="verify(index)"></el-input>
              <div>{{item.keyHour}}点</div>
            </div>
          </div>
          <div v-else style="color: red">暂时无法放单</div>
        </el-form-item>
      </el-form>
  </div>
</template>


<script>
// beforeCreate created beforeMount mounted 都不会触发。
import SbToolstips from '../../../../components/tooltip/tooltip.vue';
import {YYYY_MM_DD} from '../../../../tools/util';

const taskBuyHourVo = [
      {
        keyHour: 0,
        totalQuantity: 0
      },
      {
        keyHour: 1,
        totalQuantity: 0
      },
      {
        keyHour: 2,
        totalQuantity: 0
      },
      {
        keyHour: 3,
        totalQuantity: 0
      },
      {
        keyHour: 4,
        totalQuantity: 0
      },
      {
        keyHour: 5,
        totalQuantity: 0
      },
      {
        keyHour: 6,
        totalQuantity: 0
      },
      {
        keyHour: 7,
        totalQuantity: 0
      },
      {
        keyHour: 8,
        totalQuantity: 0
      },
      {
        keyHour: 9,
        totalQuantity: 0
      },
      {
        keyHour: 10,
        totalQuantity: 0
      },
      {
        keyHour: 11,
        totalQuantity: 0
      },
      {
        keyHour: 12,
        totalQuantity: 0
      },
      {
        keyHour: 13,
        totalQuantity: 0
      },
      {
        keyHour: 14,
        totalQuantity: 0
      },
      {
        keyHour: 15,
        totalQuantity: 0
      },
      {
        keyHour: 16,
        totalQuantity: 0
      },
      {
        keyHour: 17,
        totalQuantity: 0
      },
      {
        keyHour: 18,
        totalQuantity: 0
      },
      {
        keyHour: 19,
        totalQuantity: 0
      },
      {
        keyHour: 20,
        totalQuantity: 0
      },
      {
        keyHour: 21,
        totalQuantity: 0
      },
      {
        keyHour: 22,
        totalQuantity: 0
      },
      {
        keyHour: 23,
        totalQuantity: 0
      },
    ]
export default {
  data() {
    const validatorTime = (vali, value, callback) => {
      const cha = this.publishTotal - this.timeTotalNum;
      console.log('cha', cha)
      if (cha > 0) {
        return callback(new Error(`还有 ${cha} 单还没分配成交时段`))
      } else if (cha < 0) {
        return callback(new Error(`多分配了 ${Math.abs(cha)} 单`))
      } else {
        return callback()
      }
    };
    return {
      formRules:{
        paymentTime:{required:true,message:'请输入付款时间',trigger:['blur']},
        times:{required:true,validator:validatorTime,trigger:['blur']},
      },
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() < Date.now()-86400000;
        }
      },
      form:{
        paymentTime:new Date(),
      },
      isOneDay:true,
      disabledDate:(newDate)=>{
        console.log(newDate)
        return false;
      },
      timeTotalNum:0,
      taskBuyHourVo: []
    }
  },

  props: {
    rules:{
      type:Object,
    },
    publishTotal:{
      type:Number,
    },
    selectTimeInfo: {
      type: Boolean,
      default: () => {
        return true
      }
    },
  },
  methods: {
    validate(){
      let flag = true;
      this.$refs.form.validate((validate)=>{
        flag = validate
      })
      if(flag) {
        this.$emit('success', {...this.form,taskBuyHourVo:this.taskBuyHourVo})
      }
      return flag
    },
    verify(){
      let timeTotal = 0;
      this.taskBuyHourVo.forEach(item=>{
        timeTotal += Number(item.totalQuantity)
      })
      this.timeTotalNum = timeTotal;

    },
    startTimeChange(newDate) {
      const oldDate = new Date()
      const newD = YYYY_MM_DD(newDate)
      const oldD = YYYY_MM_DD(oldDate)
      this.isOneDay = newD === oldD;
      if(this.isOneDay){
        this.taskBuyHourVo.forEach(item=>{
          if(item.keyHour < oldDate.getHours()){
            item.totalQuantity = 0
          }
        })
      }
    },
  },
  components: {
    SbToolstips
  },

  watch: {
    publishTotal:{
      handler(){
        this.$refs.form.validate()
      }
    },
    rules:{
      handler(newV){
        const times = newV.hourPeriod.split(',');
        const filterList = taskBuyHourVo.filter(item=>{
          item.totalQuantity = 0;
          return times.indexOf(String(item.keyHour))  != -1;
        })
        this.taskBuyHourVo = filterList;
      }
    },
  },

  beforeCreate() {

  },
  created() {
    // var timenow = new Date();
    // var second = timenow.getHours() * 3600; //小时
    // second += timenow.getMinutes() * 60; //分钟
    // second += timenow.getSeconds(); //秒
    // var remain = 86340 - second; //剩余秒
    this.form.paymentTime = new Date();
    // this.value2 = new Date(new Date().getTime() + (remain * 1000))
    // var data = this.value1.getHours() + ':' + this.value1.getMinutes() + ':00' + ' - ' + this.value2.getHours() +
    // 	':' + this.value2.getMinutes() + ':00'
    // this.$set(this.times1, 'selectableRange', data)
    // this.$set(this.times2, 'selectableRange', data)
  },
  mounted() {

  },
  computed: {
    // verify() {
    // 	return this.data
    // }
  },

}
</script>

<style lang="less" scoped>

.inputWrapwrap {
  display: flex;
  flex-wrap: wrap;
  /deep/.el-input__inner{
    padding: 0 5px !important;
    text-align: center;
  }
}

.inputWrap {
  text-align: center;
  margin-right: 10px;
}

.marBot {
  margin-bottom: 15px;
}
</style>
