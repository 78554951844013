<template>
	<div>
		<el-card class="more-card">
			<div slot="header">
				更多设置
			</div>
			<div>
				<el-collapse v-model="activeName" accordion>
					<el-collapse-item name="1">
						<template slot="title" class="titles">
							<div class="titles">
								<div>
									安全优化设置（展开选择更多安全优化设置，如 <span class="orangeQian">复购、会员等级、淘气值限制、深度浏览</span>等）
								</div>
								<span class="text">点击展开</span>
							</div>
						</template>
						<div class="content">
							<el-form :model="taskSafetyVo" ref="safeForm" size="medium" label-position="top">
               <template v-if="oneTaskInfo.taskVo.platformId == 1">
                 <!--淘气值限制-->
                 <el-form-item v-if="taoqiNumList.length">
                   <div style="display: flex;align-items: center;">
                     <span class="dian"></span>
                     淘气值限制(例:选择了淘气值范围只有范围及以上的买家能接单)
                   </div>
                   <div>
                     <el-radio-group v-model="taskSafetyVo.naughtyValue">
                       <el-row>
                         <el-col :span="6" v-for="(item,index) in taoqiNumList" :key="index"
                                 style="margin-bottom: 10px;">
                           <el-radio :label="Number(item.dictValue)">{{item.dictLabel}}</el-radio>
                         </el-col>
                       </el-row>
                     </el-radio-group>
                   </div>
                 </el-form-item>
                 <!--淘宝 会员等级-->
                 <el-form-item v-if="gradeList.length">
                   <div style="display: flex;align-items: center;">
                     <span class="dian"></span>
                     会员等级（淘宝）(例:会员等级,例如：“选了3心~4心就只能3心~4心以上的买家接单”)
                   </div>
                   <div>
                     <el-radio-group v-model="taskSafetyVo.buyerLevel" style="width: 100%">
                       <el-row>
                         <el-col :span="6" v-for="(item,index) in gradeList" :key="index"
                                 style="margin-bottom: 10px;">
                           <el-radio :label="Number(item.dictValue)">{{item.dictLabel}}</el-radio>
                         </el-col>
                       </el-row>
                     </el-radio-group>
                   </div>
                 </el-form-item>
                 <!--性别-->
                 <el-form-item v-if="genderList.length ">
                   <div style="display: flex;align-items: center;">
                     <span class="dian"></span>
                     性别限制（例：选择了性别男，只能是男号才能接取任务）
                   </div>
                   <div  style="width: 100%">
                     <el-radio-group v-model="taskSafetyVo.genderFlag"  style="width: 100%">
                       <el-row>
                         <el-col :span="6" style="margin-bottom: 10px;" v-for="(item,index) in genderList" :key="index">
                           <el-radio :label="Number(item.dictValue)" >{{item.dictLabel}}</el-radio>
                         </el-col>
                       </el-row>
                     </el-radio-group>
                   </div>
                 </el-form-item>
               </template>

                <!-- 重复购买限制-->
                <el-form-item  v-if="buyLimit.length">
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
											重复购买限制(例:选择了30天不重复,30天之前的接取过您发布任务的买家无法接取此任务)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskSafetyVo.repeatPurchase" >
												<el-row>
													<el-col :span="7" v-for="(item,index) in buyLimit" :key="index"
														style="margin-bottom: 10px;">
														<el-radio :label="Number(item.dictValue)">{{item.dictLabel}}</el-radio>
													</el-col>
												</el-row>
											</el-radio-group>
										</div>
								</el-form-item>
                <!--买家假聊-->
								<el-form-item  v-if="chatFlagList.length">
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
											是否需要买家假聊(0.00元/单 买家模拟真实购物进行聊天)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskSafetyVo.fakeFlag" style="width: 100%">
												<el-row>
													<el-col :span="6" style="margin-bottom: 10px;" v-for="(item,index) in chatFlagList" :key="index">
														<el-radio :label="Number(item.dictValue)" >{{item.dictLabel}}</el-radio>
													</el-col>
												</el-row>
											</el-radio-group>
										</div>
								</el-form-item>
                <!--寻找关键词深度浏览-->
								<el-form-item >
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
											寻找关键词深度浏览：(0.00元/单,通过设置关键词来增加买家在商品详情的停留时间)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskSafetyVo.chaFlag" @change="keysFlagChange" style="width: 100%;">
												<el-row style="margin-bottom: 10px;display: flex;align-items: center;">

                          <el-col :span="4" style="display: flex;align-items: center;">
														<el-radio :label="0">不需要（免费）</el-radio>
													</el-col>
													<el-col :span="4" >
														<el-radio :label="1">需要</el-radio>
													</el-col>

                          <el-col :span="16" style="display: flex;align-items: center;">
                           <span>
															<el-form :model="taskSafetyVo" label-position="right" :rules="formRules"
                                       :inline="true" style="display: flex" ref="fromskey" size="medium">
																<el-form-item label="拟定完整内容" label-width="120px" prop="chaContent" >
																	<el-input v-model="taskSafetyVo.chaContent"
                                            style="width: 200px;"
                                            :disabled="taskSafetyVo.chaFlag == 0">
                                  </el-input>
																</el-form-item>
																<el-form-item label="内容中的关键词" label-width="120px"  prop="chaKey">
																	<el-input v-model="taskSafetyVo.chaKey"
                                            style="width: 100px;"
                                            :disabled="taskSafetyVo.chaFlag == 0">
                                  </el-input>
																</el-form-item>
															</el-form>
														</span>
                          </el-col>
												</el-row>
											</el-radio-group>
											<el-row style="padding-top: 20px">
												<sb-operatip :tipStyle="{padding:0}">
													拟定完整内容：<span class="orangeQian">面料是加厚面料</span> 内容中的关键词：<span
														class="orangeQian">加厚</span>，此时买家接取任务显示：<span
														class="orangeQian">面料是**面料</span>
												</sb-operatip>
											</el-row>
										</div>
								</el-form-item>
							</el-form>
						</div>
					</el-collapse-item>
<!--					<el-collapse-item name="2">-->
<!--						<template slot="title" class="titles">-->
<!--							<div class="titles">-->
<!--								<div>-->
<!--									千人千面设置（展开选择更多千人千面设置，如 <span class="orangeQian">如性别、婚姻、消费能力、年龄和类目标签</span>等）-->
<!--								</div>-->
<!--								<span class="text">点击展开</span>-->
<!--							</div>-->
<!--						</template>-->
<!--						<div class="content">-->
<!--							<el-form :model="diversityForm" ref="diversityForm" size="medium" label-position="top">-->
<!--								<el-form-item>-->
<!--									<div slot="label">-->
<!--										<div style="display: flex;align-items: center;">-->
<!--											<span class="dian"></span>-->
<!--											需要的人群年龄段(1.00元/单 选择接取任务的买家年龄区间段)-->
<!--										</div>-->
<!--										<div style="width: 100%;">-->
<!--											<el-radio-group v-model="diversityForm.isChat">-->
<!--												<el-row>-->
<!--													<el-col :span="6" v-for="(item,index) in buyAge" :key="index"-->
<!--														style="margin-bottom: 10px;">-->
<!--														<el-radio :label="item.id">{{item.name}}</el-radio>-->
<!--													</el-col>-->
<!--												</el-row>-->
<!--											</el-radio-group>-->
<!--										</div>-->
<!--									</div>-->
<!--								</el-form-item>-->
<!--								<el-form-item>-->
<!--									<div slot="label">-->
<!--										<div style="display: flex;align-items: center;">-->
<!--											<span class="dian"></span>-->
<!--											买家消费能力(1.00元/单 选择接取任务的买家常购物的下单价格范围)-->
<!--										</div>-->
<!--										<div style="width: 100%;">-->
<!--											<el-radio-group v-model="diversityForm.isChat">-->
<!--												<el-row>-->
<!--													<el-col :span="4" v-for="(item,index) in buyPower" :key="index"-->
<!--														style="margin-bottom: 10px;">-->
<!--														<el-radio :label="item.id">{{item.name}}</el-radio>-->
<!--													</el-col>-->
<!--												</el-row>-->
<!--											</el-radio-group>-->
<!--										</div>-->
<!--									</div>-->
<!--								</el-form-item>-->
<!--								<el-form-item>-->
<!--									<div slot="label">-->
<!--										<div style="display: flex;align-items: center;">-->
<!--											<span class="dian"></span>-->
<!--											婚姻状况(1.00元/单 选择接取任务的买家账号是否已婚)-->
<!--										</div>-->
<!--										<div style="width: 100%;">-->
<!--											<el-radio-group v-model="diversityForm.isChat">-->
<!--												<el-row>-->
<!--													<el-col :span="4" v-for="(item,index) in marital" :key="index"-->
<!--														style="margin-bottom: 10px;">-->
<!--														<el-radio :label="item.id">{{item.name}}</el-radio>-->
<!--													</el-col>-->
<!--												</el-row>-->
<!--											</el-radio-group>-->
<!--										</div>-->
<!--									</div>-->
<!--								</el-form-item>-->
<!--								<el-form-item>-->
<!--									<div slot="label">-->
<!--										<div style="display: flex;align-items: center;">-->
<!--											<span class="dian"></span>-->
<!--											选择需要的人群性别(1.00元/单 选择接取任务的买家账号性别)-->
<!--										</div>-->
<!--										<div style="width: 100%;">-->
<!--											<el-radio-group v-model="diversityForm.isChat">-->
<!--												<el-row>-->
<!--													<el-col :span="4" v-for="(item,index) in marital" :key="index"-->
<!--														style="margin-bottom: 10px;">-->
<!--														<el-radio :label="item.id">{{item.name}}</el-radio>-->
<!--													</el-col>-->
<!--												</el-row>-->
<!--											</el-radio-group>-->
<!--										</div>-->
<!--									</div>-->
<!--								</el-form-item>-->
<!--								<el-form-item label-width="0">-->
<!--									<div>-->
<!--										<el-checkbox v-model="diversityForm.oftenTag">经常购买的类目标签（1.00元/单，可多选）-->
<!--										</el-checkbox>-->
<!--									</div>-->
<!--									<div v-if="diversityForm.oftenTag">-->
<!--										<el-checkbox-group v-model="diversityForm.oftenTagId">-->
<!--											<el-row>-->
<!--												<el-col :span="3" v-for="(item,index) in oftenTagList" :key="index"-->
<!--													style="margin-bottom: 10px;">-->
<!--													<el-checkbox :label="item.id">{{item.name}}</el-checkbox>-->
<!--												</el-col>-->
<!--											</el-row>-->

<!--										</el-checkbox-group>-->
<!--									</div>-->
<!--								</el-form-item>-->
<!--							</el-form>-->
<!--						</div>-->
<!--					</el-collapse-item>-->
					<el-collapse-item name="3">
						<template slot="title" class="titles">
							<div class="titles">
								<div>
									其他增值服务（展开选择更多增值服务）
								</div>
								<span class="text">点击展开</span>
							</div>
						</template>
						<div class="content">
							<el-form :model="taskWorthVo" ref="taskWorthVo" size="medium" label-position="top">
                <!--新买家-->
								<el-form-item v-if="newUserFlagList.length">
									<div slot="label">
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
                      是否需要新买家接取任务(1.50元/单 选择此服务后，要求平台15天内注册的新买家接取任务，有效降低店铺复购率)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskWorthVo.newUserFlag">
												<el-radio :label="item.dictValue" v-for="(item,index) in newUserFlagList" :key="index">{{item.dictLabel}}</el-radio>
											</el-radio-group>
										</div>
									</div>
								</el-form-item>
                <!--老用户下单-->
								<el-form-item v-if="oldUserFlagList.length">
									<div slot="label">
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
                      是否需要老用户下单(免费， 免费， 免费，合理复购率可以增加点的复购和标签权重)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskWorthVo.oldUserFlag">
                        <el-radio :label="item.dictValue" v-for="(item,index) in oldUserFlagList" :key="index">{{item.dictLabel}}</el-radio>
											</el-radio-group>
										</div>
									</div>
								</el-form-item>
                <!--花呗的买家-->
								<el-form-item v-if="oneTaskInfo.taskVo.platformId&&huaBeiFlagList.length">
									<div slot="label">
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
                      是否需要账号开通花呗/白条(开通花呗限制,例:选择了“是”就只有开通花呗的买家才能接单)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskWorthVo.huaBeiFlag">
                        <el-radio :label="item.dictValue" v-for="(item,index) in huaBeiFlagList" :key="index">{{item.dictLabel}}</el-radio>
											</el-radio-group>
										</div>
									</div>
								</el-form-item>
                <!--信用卡/花呗/白条/(免费 信用卡/花呗/白条-->
								<el-form-item v-if="huaBeiFlagList.length">
									<div slot="label">
										<div style="display: flex;align-items: center;">
											<span class="dian"></span>
                      是否允许使用信用卡/花呗/白条/(免费 信用卡/花呗/白条限制,例:选择了“否”表示不允许买家通过信用卡/花呗/白条)
										</div>
										<div style="width: 100%;">
											<el-radio-group v-model="taskWorthVo.allowCreditFlag">
                        <el-radio :label="item.dictValue" v-for="(item,index) in allowCreditFlagList" :key="index">{{item.dictLabel}}</el-radio>
                      </el-radio-group>
										</div>
									</div>
								</el-form-item>
                <!--问大家/问答-->
<!--								<el-form-item >-->
<!--										<div style="display: flex;align-items: center;">-->
<!--											<span class="dian"></span>-->
<!--											问大家/问答:（1.00元/单，如果:选择需要买家按照指定的问题提问）-->
<!--										</div>-->
<!--										<div style="width: 100%;">-->
<!--                      <el-radio-group v-model="taskWorthVo.issueFlag" @change="questionChang" style="width: 100%;">-->
<!--												<el-row>-->
<!--													<el-col :span="8"-->
<!--														style="margin-bottom: 10px;height: 36px;display: flex;align-items: center;">-->
<!--														<el-col :span="12"  v-for="(item,index) in issueFlagList" :key="index" >-->
<!--															<el-radio :label="item.dictValue">{{item.dictLabel}}</el-radio>-->
<!--														</el-col>-->
<!--													</el-col>-->
<!--													<el-col :span="16" style="margin-bottom: 10px;" class="questions">-->
<!--														<el-form :model="questionsForm" ref="questions"-->
<!--															label-width="80px" size="medium" label-position="right">-->
<!--															<template v-for="(item,index) in questionList">-->
<!--																<el-form-item :key="index" :rules="[{-->
<!--                                    required: taskWorthVo.issueFlag == 1,-->
<!--                                    message: '请输入提问问题',-->
<!--                                    trigger: ['blur']-->
<!--                                  }]"-->
<!--																	style="margin-bottom: 20px;display: flex;" :prop="item.name"-->
<!--																	label="提问问题：">-->
<!--																	<el-input v-model="questionsForm[item.name]"-->
<!--                                            @blur="blurs($event,index)"-->
<!--																		style="width: 250px;margin-right: 15px;"-->
<!--																		:disabled="taskWorthVo.issueFlag != 1">-->
<!--																	</el-input>-->
<!--																	<el-button size="small" plain-->
<!--																		@click="addQustions"-->
<!--																		:disabled="taskWorthVo.issueFlag != 1"-->
<!--																		v-if="index == 0">-->
<!--																		<i class="el-icon-plus"></i>添加-->
<!--																	</el-button>-->
<!--																	<el-button size="small" plain-->
<!--																		@click="delQustions(index)" v-else>-->
<!--																		<i class="el-icon-minus"></i>删除-->
<!--																	</el-button>-->
<!--																</el-form-item>-->
<!--															</template>-->
<!--														</el-form>-->
<!--													</el-col>-->
<!--												</el-row>-->
<!--											</el-radio-group>-->
<!--										</div>-->
<!--								</el-form-item>-->


							</el-form>
						</div>
					</el-collapse-item>
				</el-collapse>
        <div>
          <div style="line-height: 18px !important;padding: 15px 0;">
            额外说明（免费,如果您有特别的说明要求,请在备注里说明，
            <span   style="color: rgb(255, 113, 75);">为了保障商家和买家的正常权益，
              敬请谅解！</span>）
          </div>
          <div>
            <el-input type="textarea"
                      :rows="5"
                      placeholder="如果对买家有特别的要求，请在备注里注明，买家在做任务时会看到，最多不能超过150字（任务备注只是商家要求，我们只能做到传达给买家但不会强制买家按要求执行）"
                      v-model="taskWorthVo.requireDesc">
            </el-input>
          </div>
        </div>
			</div>
		</el-card>

<!--    <div @click="chaValidate">查文&#45;&#45;&#45;&#45;</div>-->
<!--    <div @click="qustionsValidate">查文&#45;&#45;&#45;&#45;</div>-->
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
	import SbOperatip from '../opera_tip/opera_tip.vue';

	export default {
		data() {
      const isExist = (rule, value, callback) => {
        let errMes = '';
        if (this.taskSafetyVo.chaFlag == 0) {
          return callback()
        }
        this.$refs.fromskey.validateField('chaContent', err => {
          errMes = err
        });
        console.log(errMes)
        if (!errMes) {
          console.log(this.taskSafetyVo.chaContent.indexOf(value))
          if (value==''||this.taskSafetyVo.chaContent.indexOf(value) == -1) {
            return callback(new Error('关键字没在拟定内容里面'))
          } else {
            return callback()
          }
        } else {
          return callback(new Error('请输入拟定的内容'))
        }
      }
			return {
        newUserFlagList: [
          {dictValue: 0, dictLabel: '否（免费）',},
          {dictValue: 1, dictLabel: '是（1.50元/单）',},
        ],
        oldUserFlagList: [
          {dictValue: 0, dictLabel: '否（免费）',},
          {dictValue: 1, dictLabel: '是（免费）',},
        ],
        huaBeiFlagList: [
          {dictValue: 0, dictLabel: '无要求（免费）',},
          {dictValue: 1, dictLabel: '否（1.50元/单）',},
          {dictValue: 2, dictLabel: '是（1.50元/单）',},
        ],
        allowCreditFlagList: [
          {dictValue: 0, dictLabel: '否（免费）',},
          {dictValue: 1, dictLabel: '是（免费）',},
        ],
        issueFlagList: [
          {dictValue: 0, dictLabel: '不需要（免费）',},
          {dictValue: 1, dictLabel: '需要：',},
        ],
				activeName: '',
				questionsForm: {
					value1: ''
				},
				questionList: [{
					value: '',
					name: 'value',
          data:'',
				}],
				questionsRules: {

				},
				taskWorthVo: {
          allowCreditFlag: 0,
          huaBeiFlag: 0,
          issueContent: '',
          issueFlag: 0,
          newUserFlag: 0,
          oldUserFlag: 0,
          requireDesc:'',
				},

				diversityForm: {
					oftenTag: false,
					oftenTagId: []
				},
        taskSafetyVo: {
          naughtyValue:'',
          buyerLevel:'',
          repeatPurchase:'',
          fakeFlag:'',
          genderFlag:'',
          chaContent:'',
          chaKey:'',
          chaFlag:'',
				},

        formRules: {
          chaContent: [{required: false, message: '请输入拟定的完整内容', trigger: ['change', 'blur']}],
          chaKey: [{required: false, validator: isExist, trigger: ['blur', 'change']}],
        },
				oftenTagList: [
            {
						id: 1,
						name: '生鲜'
					},
					{
						id: 2,
						name: '酒类'
					},
					{
						id: 3,
						name: '礼品箱包'
					},
					{
						id: 4,
						name: '家用电器'
					},
					{
						id: 5,
						name: '户外活动'
					},
					{
						id: 6,
						name: '珠宝首饰'
					},
					{
						id: 7,
						name: '钟表'
					},
					{
						id: 8,
						name: '服饰鞋履'
					},
					{
						id: 9,
						name: '电脑办公'
					},
					{
						id: 10,
						name: '医药保健'
					},
					{
						id: 11,
						name: '手机数码'
					},
					{
						id: 12,
						name: '美妆养护'
					},
					{
						id: 13,
						name: '玩具乐器'
					},
					{
						id: 14,
						name: '宠物生活'
					},
					{
						id: 15,
						name: '农资绿植'
					},
					{
						id: 16,
						name: '教育服务'
					},
					{
						id: 17,
						name: '包装'
					},
					{
						id: 18,
						name: '其他'
					},
					{
						id: 19,
						name: '母婴'
					},
					{
						id: 20,
						name: '食品饮料'
					},
					{
						id: 21,
						name: '汽车用品'
					},
					{
						id: 22,
						name: '家装材料'
					},
					{
						id: 23,
						name: '家居家纺'
					},
					{
						id: 24,
						name: '厨具'
					},
					{
						id: 24,
						name: '家具'
					},
				],
				sex: [
            {
						id: 1,
						name: '无要求（免费）'
					},
					{
						id: 2,
						name: '男'
					},
					{
						id: 3,
						name: '女'
					},
				],
				marital: [
            {
						id: 1,
						name: '无要求（免费）'
					},
					{
						id: 2,
						name: '未婚'
					},
					{
						id: 3,
						name: '已婚'
					},
				],
				buyPower: [
            {
						id: 1,
						name: '无要求（免费）'
					},
					{
						id: 2,
						name: '0-50元'
					},
					{
						id: 3,
						name: '50-100元'
					},
					{
						id: 4,
						name: '50-100元'
					},
					{
						id: 5,
						name: '100-200元'
					},
					{
						id: 6,
						name: '200-500元'
					},
					{
						id: 7,
						name: '500-1000元'
					},
					{
						id: 8,
						name: '1000-3000元'
					},
					{
						id: 9,
						name: '3000-5000元'
					},
					{
						id: 10,
						name: '5000元以上'
					},
				],
				buyAge: [
            {
						id: 1,
						name: '无要求（免费）'
					},
					{
						id: 2,
						name: '15-25岁'
					},
					{
						id: 3,
						name: '26-35岁'
					},
					{
						id: 4,
						name: '36岁以上'
					},
				],
        chatFlagList: [

        ],
				buyLimit: [

				],
				taoqiNumList: [

				],
				gradeList: [

				],
        genderList:[

        ],
			}
		},
		props: {
      taskType: {},
      cacheTwoTaskInfo: {},
      oneTaskInfo: {},
		},
    watch:{
      cacheTwoTaskInfo:{
        handler(newVal){
          this.taskSafetyVo = newVal.taskSafetyVo;
          this.taskWorthVo = newVal.taskWorthVo;
          if(this.taskWorthVo.issueFlag){
            const list = this.taskWorthVo.issueContent.split(',')
            this.questionList = []
            list.forEach((item,index)=>{
              this.addQustions(item)
              this.questionsForm[this.questionList[index].name] = item;
              console.log(this.questionsForm)
            })
          }
        }
      }
    },
		methods: {
      blurs(event,index){
        console.log(event)
        this.questionList[index].data = event.target.value;
      },
			questionChang() {
        this.questionList = [{
          value1: '',
          name: 'value1'
        }]
        this.$refs.questions?.clearValidate()

      },

			delQustions(index) {

				// var index = index + 1;
				delete this.questionsForm[this.questionList[index].name]
        this.questionList.splice(index, 1)

      },
			addQustions(value) {
        const name =  'value' +new Date().getTime()
				this.questionList.push({
					name,
          [name]:'',
          value,
				})

			},
      keysFlagChange(value) {
        console.log(value)
				// 清空 框里面的内容
        this.taskSafetyVo.chaContent = '';
        this.taskSafetyVo.chaKey = '';
        this.$refs.fromskey.clearValidate()
        if (value == 0) {
          this.$set(this.formRules.chaKey[0], 'required', false);
          this.$set(this.formRules.chaContent[0], 'required', false);
        } else {
          this.$set(this.formRules.chaKey[0], 'required', true);
          this.$set(this.formRules.chaContent[0], 'required', true);
        }
			},
      validate(){
        const qustions = this.qustionsValidate();
        const cha = this.chaValidate();
        if(qustions&&cha){
          let arr = []
          console.log(this.questionList)
          this.questionList.forEach(item=>{
            if(item.data){
              arr.push(item.data)
            }
          })
          this.taskWorthVo.issueContent = arr.join()
          const map = {
            taskWorthVo:this.taskWorthVo,
            taskSafetyVo:this.taskSafetyVo,
          }
          this.$emit('success',map)
        }
        return qustions&&cha
      },
      qustionsValidate(){
        let  iss = true;
        this.$refs.questions?.validate((validate)=>{
          if(!validate){
            iss = validate
          }
        });
        return iss
      },
      chaValidate(){
        let  iss = true;
         this.$refs.fromskey.validate((validate)=>{
           if(!validate){
             iss = validate
           }
         });
         return iss
      },
			proChange(e) {
				console.log(e)
			}
		},
		components: {
			SbOperatip,
		},
		mounted() {
      this.$axios.get('/dict/select', {dictType:'tb_naughty_value,tb_buyer_level,tb_repeat_purchase,tb_chat_flag,tb_gender'}).then(resp => {
        if(resp.code == 200){
          this.taoqiNumList = resp.data['tb_naughty_value'];
          this.taskSafetyVo.naughtyValue = Number(this.taoqiNumList[0]?.dictValue);

          this.gradeList = resp.data['tb_buyer_level'];
          this.taskSafetyVo.buyerLevel = Number(this.gradeList[0]?.dictValue);

          this.buyLimit = resp.data['tb_repeat_purchase'];
          this.taskSafetyVo.repeatPurchase = Number(this.buyLimit[0]?.dictValue);

          this.chatFlagList = resp.data['tb_chat_flag'];
          this.taskSafetyVo.fakeFlag = Number(this.chatFlagList[0]?.dictValue);

          this.genderList = resp.data['tb_gender'];
          this.taskSafetyVo.genderFlag = Number(this.chatFlagList[0]?.dictValue);

          this.taskSafetyVo.chaFlag = 0;
          this.$set(this.formRules.chaKey[0], 'required', false);
          this.$set(this.formRules.chaContent[0], 'required', false);
        }

      })
		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.groupClass {
		.el-row {
			padding-top: 15px;
		}
	}

	.radioClass {
		margin-bottom: 25px;
		label {
			line-height: 0px;
		}
	}

	.questions {
		/deep/ .el-form-item__content {
			margin-left: 20px !important;
		}
	}

	.dian {
		width: 5px;
		height: 5px;
		background-color: #666;
		margin-right: 5px;
		display: inline-block;
	}

	.content {
		background-color: #fafafa;
		padding: 20px;
		border: 1px solid #eee;
	}

	.titles {
		width: 100%;
		position: relative;

		.text {
			position: absolute;
			right: 0;
			top: 0;
		}
	}

	.more-card {
		.el-form-item {
			margin-bottom: 0px;
		}

		/deep/ .el-form-item__label {
			width: 100%;
			padding-bottom: 0px;

			.el-radio-group {
				width: 100%;
			}
		}
	}

	.el-collapse {
		border-top: 0;
		border-bottom: 0;
	}

	/deep/ .el-collapse-item__wrap {
		border-bottom: 0;
	}

	/deep/ .el-collapse-item__header {
		border-bottom: 0;
	}
</style>
