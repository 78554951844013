<template>
    <el-row  style="display: flex;align-items: center">
      <el-col  :span="12">
        <el-select @change="proChange($event)" placeholder="请选择省" style="width: 100px" v-model="proId" show-search>
          <el-option :key="index" :value="item.areaId" v-for="(item,index) in proList" :filterable="true" :label="item.areaName" >
          </el-option>
        </el-select>
      </el-col>
      <el-col  :span="12">
        <el-select @change="areaChange($event)" placeholder="请选择市" style="width: 100px;margin-left: 8px"
                   v-model="areaId">
          <el-option :key="index" :value="item.areaId" v-for="(item,index) in areaList" :filterable="true" :label="item.areaName" >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
</template>

<script>

export default {
  data() {
    return {
      proList: [],
      areaList: [],
      proId:'',
      areaId:'',
    }
  },
  props: {
    areaName: {},
    proName: {},
  },
  watch: {
    proName:{
      async handler(newV){
        if(newV){
          let list = this.proList.filter(item=>{
            return  item.areaName == newV
          })
          this.proId = list[0].areaId;
          const areaList = await this.getAreaList(this.proId)
          console.log(areaList)
          const filterList = areaList.filter(item=>{
            return  item.areaName == this.areaName
          })
          this.areaId = filterList[0].areaId
        }
      },
      immediate:true,
    },
    areaName:{
      handler(newV){
        if(newV){
          let list = this.proList.filter(item=>{
            return  item.areaName == newV
          })
          console.log(list)
          this.areaId = list[0]?.areaId;
        }
      },
    }
  },
  mounted() {
    this.getProList()
  },
  methods: {
    getValue(){
      return {
        areaId:this.areaId,
        areaName:this.areaName,
        proId:this.proId,
        proName:this.proName,
      }
    },
    areaChange(value) {
      let list = this.areaList.filter(item=>{
        return item.areaId == value
      })
      this.areaName = list[0].areaName;
      this.$emit('change',this.proName+' '+this.areaName)
    },
    proChange(value) {
      let list = this.proList.filter(item=>{
        return  item.areaId == value
      })
      this.proName = list[0].areaName;
      this.$emit('change',this.proName+' '+this.areaName)
      this.areaId = '';
      this.areaName = '';
      this.getAreaList(value)
    },
    async getProList() {
      await this.$axios.get('/area',{areaId: 0}).then(resp => {
        if (resp.code == 200) {
          this.proList = resp.data;
        }
      })
    },
    getAreaList(areaId) {
      return this.$axios.get("/area",{areaId}).then(resp => {
        if (resp.code == 200) {
          this.areaList = resp.data;
          return this.areaList
        }
      })
    }
  },
  deactivated() {

  },
  component: {}
}
</script>

<style scoped>

</style>