
export function formatTime(day){
    const Year = day.getFullYear()
    const Month = day.getMonth()+1<10?'0'+(day.getMonth()+1):day.getMonth()+1;
    const Date = day.getDate()<10?'0'+day.getDate():day.getDate();
    const Hours = day.getHours()<10?'0'+day.getHours():day.getHours();
    const Min = day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes();
    const Second = day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds();
    return {
        Year,
        Month,
        Hours,
        Min,
        Second,
        Date,
    }
}


export function YYYY_MM_DD(day){
    const dateMap  =  formatTime(day)
    return  dateMap.Year+'-'+ dateMap.Month+'-'+ dateMap.Date
}



