<template>
    <el-row style="display: flex;align-items: center">
      <el-col :span="12">
        <el-input @input="handleInput1" v-model="startPrice" placeholder="开始价格"  @blur="priceBlur"
                  style="width:100px">
        </el-input>
      </el-col>
      <el-col :span="12">
        <el-input @input="handleInput2" v-model="endPrice" placeholder="结束价格" @blur="priceBlur" @change="change"
                  style="width:100px;">
        </el-input>
      </el-col>
    </el-row>
</template>

<script>
export default {
  data() {
    return {
      startPrice: '',
      endPrice: '',
    }
  },
  props: {
    rangePrice: {

    }
  },
  watch: {
    rangePrice: {
      handler(newVal) {
        if (newVal) {
          const arr = newVal.split('~');
          this.startPrice = arr[0];
          this.endPrice = arr[1];
        } else {
          this.startPrice = '';
          this.endPrice = '';
        }
      },
    },
  },
  mounted() {

  },
  methods: {
    handleInput1() {
      const str = String(this.startPrice).replace(/[^\d.]/g, '');
      this.startPrice = Number(str)
    },
    handleInput2() {
      let str = String(this.endPrice).replace(/[^\d.]/g, '');
      this.endPrice = Number(str)
    },
    priceBlur() {
      if (this.startPrice > this.endPrice) {
        this.endPrice = Number(this.startPrice) + 1
      }
      this.$emit('change', [this.startPrice, this.endPrice])
    },
    change() {
      this.$emit('change', [this.startPrice, this.endPrice])
    }
  },
  deactivated() {

  },
  component: {}
}
</script>

<style scoped>

</style>