<template>
	<div>
		<el-card class="box-card">
			<div slot="header">
				刷选条件设置
			</div>
			<div>
				<el-collapse  accordion>
					<el-collapse-item name="1">
						<template slot="title" style="width: 100%;position: relative;">
							<div style="width: 100%;position: relative;">
								<div>
									卡条件定位搜索商品（新品不好找，可设置筛选条件便于买家找到目标商品)
								</div>
								<span style="position: absolute;right: 0;top: 0;">点击展开</span>
							</div>
						</template>
						<div style="background-color: #fafafa;padding: 20px;border: 1px solid #eee;">
							<el-form :model="taskScreeningVo" ref="searchForm" size="medium" >
								<el-form-item label="定位目标商品排序方式：" label-width="200px">
									<el-radio-group v-model="taskScreeningVo.sortBy">
                    <el-radio label="综合">综合</el-radio>
                    <el-radio label="销量">销量</el-radio>
									</el-radio-group>
								</el-form-item>
								<el-row>
									<el-col :span="12">
										<el-form-item label="商品现在有收货人数约：" label-width="200px">
											<el-input v-model="taskScreeningVo.receiptNum" style="width: 220px;" type="number"
												onkeyup="this.value=this.value.replace(/[^\d]/g,'');"></el-input>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="选择商品所在地：" label-width="200px">
											<sb-area  :areaName="taskScreeningVo.location&&taskScreeningVo.location.split(' ')[1]"
                                ref="area"
                                @change="locationChange"
                                :proName="taskScreeningVo.location&&taskScreeningVo.location.split(' ')[0]">
                      </sb-area>
										</el-form-item>
									</el-col>
								</el-row>
								<el-row>
									<el-col :span="12">
										<el-form-item label="填写价格区间：" label-width="200px">
                      <price-select :rangePrice="taskScreeningVo.rangePrice" @change="priceChange">
                      </price-select>
										</el-form-item>
									</el-col>
									<el-col :span="12">
										<el-form-item label="商品现有销售约：" label-width="200px">
											<el-input v-model="taskScreeningVo.soldNum" style="width: 100px;" type="number"
												onkeyup="this.value=this.value.replace(/[^\d]/g,'');"></el-input>
										</el-form-item>
									</el-col>
								</el-row>
<!--								<el-form label-position="top">-->
<!--									<el-form-item label="商品定位（如果商品排名靠后时建议勾选,便于买家搜索）：">-->
<!--										<el-checkbox-group v-model="positionSelect">-->
<!--											<el-checkbox v-for="item in positionList" :label="item.key" :key="item.key">{{item.name}}-->
<!--											</el-checkbox>-->
<!--										</el-checkbox-group>-->
<!--									</el-form-item>-->
<!--								</el-form>-->


							</el-form>
						</div>
					</el-collapse-item>
				</el-collapse>
			</div>
		</el-card>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
  import SbArea from '../area/area'
  import PriceSelect from '../priceSelect/priceSelect'
	export default {
		data() {
			return {
				positionSelect: [],
				positionList: [
					// {key:1,name:'包邮'},
					// {key:2,name:'天猫'},
					// {key:3,name:'全球购'},
					// {key:4,name:'消费者保障'},
					// {key:5,name:'淘金币抵钱'},
					// {key:6,name:'货到付款'},
					// {key:7,name:'7+天退换'},
					// {key:8,name:'花呗分期'},
					// {key:9,name:'天猫超市'},
					// {key:10,name:'天猫国际'},
					// {key:11,name:'通用排序'},
				],

				taskScreeningVo: {
          sortBy: '',
          receiptNum: '',
          location: '',
          rangePrice:'',
          soldNum:'',
				},
				proList: [],
				cityList: [],
			}
		},
		props: {
      taskType: {},
      cacheTwoTaskInfo: {},
      oneTaskInfo: {},
		},
    watch:{
      cacheTwoTaskInfo:{
        handler(newVal){
          console.log(newVal)
          if(!newVal.taskScreeningVo)return
          for( let key in this.taskScreeningVo){
            this.taskScreeningVo[key] = newVal.taskScreeningVo[key]
          }
        }
      }
    },
		methods: {
      locationChange(location){
        this.taskScreeningVo.location = location
      },
      validate(){
        let flag = true;
        this.$refs.searchForm.validate(validate=>{
          flag = validate
        })
        if(flag){
          this.$emit('success',{  taskScreeningVo:this.taskScreeningVo })
        }
        return flag
      },
      priceChange(rangePrice) {
        this.taskScreeningVo.rangePrice = rangePrice.join('~');
      },
			proChange(e) {
				console.log(e)
			},

		},
		components: {
      SbArea,
      PriceSelect,
		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},
	}
</script>

<style lang="less" scoped>
	.box-card{
		margin-bottom: 20px;
	}
	.el-collapse {
		border-top: 0;
		border-bottom: 0;
	}

	/deep/ .el-collapse-item__wrap {
		border-bottom: 0;
	}

	/deep/ .el-collapse-item__header {
		border-bottom: 0;
	}
</style>
