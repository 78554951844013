<template>
	<div>
		<div style="width: 980px;">
			<!-- 优惠劵 -->
      <el-card class="box-card">
        <div slot="header">
          优惠劵
        </div>
        <div style="background-color: #fafafa;padding: 20px;border: 1px solid #eee;">
          <el-form>
            <el-form-item label="店铺优惠劵：" label-width="100px">
                <el-select v-model="couponGroupId" placeholder="选择优惠劵">
                  <el-option :label="item.groupName+'~共'+item.groupSize+'条'" :value="item.groupId" v-for="(item,index) in couponList" :key="index"></el-option>
                </el-select>
            </el-form-item>

          </el-form>
        </div>
      </el-card>
			
			<!-- 买家用户维度打标设置 (非直接下单关键词) -->
			<!-- <user-vdoing></user-vdoing> -->
			
			<!-- 进店途径与任务单数设置 -->
			<store-way-and-task-num  :rules="rules" ref="storeWay" :cacheTwoTaskInfo="cacheTwoTaskInfo" :platform="oneTaskInfo.taskVo.platformId" :cacheSearchList="cacheSearchList" @success="taskSuccess">
			</store-way-and-task-num>

			<!-- 刷选条件设置 -->
			<task-term  :cacheTwoTaskInfo="cacheTwoTaskInfo" ref="taskTerm"  @success="taskTermSuccess">
      </task-term>
			<!-- 更多设置 -->
			<more-setting  :oneTaskInfo="oneTaskInfo" :cacheTwoTaskInfo="cacheTwoTaskInfo" ref="moreSetting" @success="moreSetSuccess"></more-setting>
			<!-- 脚部提交按钮区域 -->
			<div class="footers">
				<div>
          <el-button type="primary" size="medium" @click="lastStep" style="width:228px;height: 50px;">上一步  </el-button>
          <el-button type="primary" size="medium" @click="nestStep" style="width:228px;height: 50px;" v-if="!$route.query.taskId">  下一步  </el-button>
          <el-button type="primary" size="medium" @click="edit" style="width:228px;height: 50px;" v-if="$route.query.taskId">  下一步  </el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	
	import TaskTerm from '@/components/taskterm/taskterm.vue'
	import MoreSetting from '@/components/moreSetting/moreSetting.vue'
	import StoreWayAndTaskNum from '@/views/taskTwo/form/storeWayAndTaskNum.vue'
  import {TASKRULES, COUPONSELECT,TASK,EDITTASK} from '../../api/base';
  import {submitForm,displayForm} from '../transformDate'

	export default {
		data() {

			return {
        couponGroupId:'',
				screenshot:0,
				oneTaskInfo:{
          taskAttachGoodsVo:{},
					baseGoods:[],
					taskVo:{
          },
				},
				// 进店数组
        cacheSearchList:[],
        couponList:[],
        twoTaskInfo:{},
        rules:{},
        cacheTwoTaskInfo:{
          form:{}
        }
			}
		},
    beforeRouteLeave(to, from, next){
      console.log(to, from, next)
      if(from.params.to == "taskone"){
        this.$destroy()
        if(from.params.taskId){
          next('/pageIndex/taskone?taskId='+from.params.taskId)
        }else{
          next('/pageIndex/taskone')
        }
      }else{
        next()
      }
    },
		methods: {
      lastStep(){
        this.$route.params.to = 'taskone'
        const taskId = this.$route.query.taskId;
        if(taskId){
          this.$route.params.taskId = taskId
        }
        this.$router.back()
      },
      edit(){
        if(this.validate()){
          this.oneTaskInfo.taskVo.couponGroupId = this.couponGroupId;
          const form =  submitForm(this.oneTaskInfo,this.twoTaskInfo)
          console.log(form)
          for(let key in form){
            form[key] =  JSON.stringify(form[key])
          }
        const taskId = this.$route.query.taskId;
          this.$axios.put(`${TASK}/${taskId}`,form).then(resp=>{
            if (resp.code == 200){
              this.$mes({message:resp.msg})
              this.$destroy()
              this.$router.push('/pageIndex/tasklist')
            }else{
              this.$mes({message:resp.msg,type:"warning"})
            }
          })
        }
      },
      nestStep(){
        if(this.validate()){
          this.oneTaskInfo.taskVo.couponGroupId = this.couponGroupId;
          const form =  submitForm(this.oneTaskInfo,this.twoTaskInfo)
          console.log(form)
          for(let key in form){
            form[key] =  JSON.stringify(form[key])
          }
          this.$axios.post(TASK,form).then(resp=>{
            if (resp.code == 200){
              this.$mes({message:resp.msg})
              this.$router.push('/pageIndex/tasklist')
              this.$destroy()
            }else{
              this.$mes({message:resp.msg,type:"warning"})
            }
          })
        }
      },
      validate(){
        const storeWay = this.$refs.storeWay.validate()
        const moreSetting = this.$refs.moreSetting.validate()
        const taskTerm = this.$refs.taskTerm.validate()
        return storeWay&&moreSetting&&taskTerm
      },
      moreSetSuccess(form){
        Object.assign(this.twoTaskInfo,form)
      },
      taskTermSuccess(form){
        Object.assign(this.twoTaskInfo,form)
      },
      taskSuccess(form){
        Object.assign(this.twoTaskInfo,form)
        // this.oneTaskInfo.taskVo = form.publishTotal;
      },
      getTaskRules(){
        this.$axios.get(TASKRULES).then(resp=>{
          if(resp.code == 200){
            this.rules = resp.data;
          }
        })
      },
      getCouponList(shopId){
        this.$axios.get(COUPONSELECT,{shopId}).then(resp=>{
          console.log(resp)
          if(resp.code == 200){
            this.couponList = resp.data;
          }
        })
      },
      displayDate(twoForm){
        console.log(twoForm)
        this.couponGroupId = this.oneTaskInfo.taskVo.couponGroupId
        this.cacheSearchList = twoForm.form.taskKeywordsVo;
      }
		},
		components: {
			TaskTerm,
			MoreSetting,
			StoreWayAndTaskNum,
			// UserVdoing,
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		mounted() {
      document.body.scrollTop=document.documentElement.scrollTop=0;
      const taskId = this.$route.query.taskId;
      const type = this.$route.query.type;

      if(taskId&&!type){
        this.$axios.get(EDITTASK,{taskId}).then(resp=>{
          console.log(resp)
          if(resp.code == 200){
            console.log(resp)
            const map =  displayForm(resp.data)

            this.twoTaskInfo = map.twoDate;
            this.cacheTwoTaskInfo = map.twoDate;

            this.oneTaskInfo = map.oneDate;
            this.displayDate(map.twoDate)
            this.getCouponList(map.oneDate.taskVo.shopId)
            this.couponGroupId =  this.oneTaskInfo.taskVo.couponGroupId;
            console.log( this.oneTaskInfo.taskVo)
            this.$store.commit('setting/setOneTaskInfo',  map.oneDate)
          }
        })
      }else{

        const oneTaskInfo = this.$store.getters['setting/oneTaskInfo'];
        this.oneTaskInfo = oneTaskInfo;
        this.getCouponList(this.$store.state.setting.oneTaskInfo.taskVo.shopId)
      }
			// if(JSON.stringify(oneTaskInfo)=='{}'){
			// 	return  this.$router.push('/pageIndex/taskOne')
			// }
      this.getTaskRules()

    },
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	.footers{
		padding: 40px 0;
		display: flex;
		justify-content: center;
		.el-button{
			margin: 0 5px;
		}
	}
	.wrap {
		position: relative;

		.add_btn {
			position: absolute;
			right: 0;
			top: 0;

			/deep/ .el-button--medium {
				padding: 10px 5px;
				width: 60px;
				background-color: #f5f5f5;
			}

		}
	}
	.demo-ruleForm {
		.tet {
			margin-left: 40px;
			color: @primaryColorQian;
		}
	}

	.box-card {
		margin-bottom: 25px;
	}

	.clearfix {
		color: @primaryColorQian;
		display: flex;
		align-items: center;

		.el-icon-warning {
			margin-right: 20px;
		}
	}
</style>
