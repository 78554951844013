import {YYYY_MM_DD} from '../tools/util';
export const submitForm = function (oneDate,twoDate) {
    console.log(oneDate,twoDate)
    // const one ={
    //     "att":[],//taskAttachGoodsVo
    //     "baseGoods":{
    //         "goodsLink":"https://item.taobao.com/item.htm?id=658923555998",
    //         "goodsTitle":"女毛衣深色可爱",
    //         "goodsPicture":"admin-goods-20220308-0d023493be3744ccab3392ed3f143983.bmp",
    //         "watermarkFlag":1,"watermarkPicture":"admin-goods-water-20220308-061b73926c45454c9136a6ba66a17e11.jpg",
    //         "displayPrice":10,
    //         "payablePrice":110,
    //         "goodsSeamen":"111",
    //         "buyAmount":1
    //     },
    //     "taskVo":{
    //         "platformId":1,
    //         "rebateMethod":"",
    //         "shopId":1,
    //         "typeCode":""
    //     }
    // }
    // const two

    //task数据转换函数
    const publishTotal = twoDate.form.publishTotal;
    const requireDesc = twoDate.taskWorthVo.requireDesc;
    const paymentTime = YYYY_MM_DD(twoDate.form.taskBuyHourVo.paymentTime);
    // delete twoDate.form.publishTotal;
    delete twoDate.form.taskBuyHourVo.paymentTime;
    delete twoDate.taskWorthVo.paymentTime;
    delete twoDate.taskWorthVo.requireDesc;
    const map = {
        taskScreeningVo:twoDate.taskScreeningVo,
        taskAttachGoodsVo:oneDate.taskAttachGoodsVo,
        taskVo: {
            ...oneDate.baseGoods,
            ...oneDate.taskVo,
            publishTotal,
            requireDesc,
            paymentTime,
        },
        taskSafetyVo:{
            ...twoDate.taskSafetyVo,
        },
        taskBuyHourVo:twoDate.form.taskBuyHourVo.taskBuyHourVo,
        taskKeywordsVo:twoDate.form.taskKeywordsVo,
        taskWorthVo:{
            ...twoDate.taskWorthVo,
        },

    }
    return map
}

export const displayForm = function (taskForm) {

    const {
        patternType,goodsNo,goodsId,goodsLink,goodsTitle,goodsPicture,watermarkFlag,displayPrice, payablePrice,goodsSeamen,
        buyAmount,platformId,rebateMethod, shopId,typeCode,unitPrice,couponGroupId,watermarkPicture
        }
        =
        taskForm.taskVo;

    const oneDate = {
        taskAttachGoodsVo:taskForm.taskAttachGoodsVo,
        taskVo:{
            platformId,
            rebateMethod,
            patternType,
            shopId,
            typeCode,
            couponGroupId,
            // requireDesc:taskForm.taskWorthVo.requireDesc
        },
        baseGoods:{
            goodsNo,
            goodsLink,
            goodsTitle,

            goodsPicture,
            watermarkFlag,
            watermarkPicture,

            displayPrice,
            payablePrice,
            goodsSeamen,
            goodsId,
            unitPrice,
            buyAmount,
        }
    }
    const twoDate = {
        taskScreeningVo:taskForm.taskScreeningVo,
        taskSafetyVo: taskForm.taskSafetyVo,
        taskWorthVo:{
            requireDesc:taskForm.taskVo.requireDesc,
            ...taskForm.taskWorthVo
        },
        form:{
            publishTotal:taskForm.taskVo.publishTotal,
            taskKeywordsVo:taskForm.taskKeywordsVo,
            taskBuyHourVo:{
                paymentTime:new Date(taskForm.taskVo.paymentTime),
                taskBuyHourVo:taskForm.taskBuyHourVo,
            }
        },

    }
    return {
        oneDate,
        twoDate,
    }
}